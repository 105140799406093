import FlashMessages from '@/react/components/FlashMessages';
import { createInertiaApp } from '@inertiajs/react';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createRoot } from 'react-dom/client';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: false,
      // refetchOnMount: false,
      // This is data that is currently NOT used in any query
      gcTime: 1000 * 60 * 30, // 30 minutes
      // This is data that is currently USED in a query on the page you are currently viewing.
      // Going outside of that page will start the gcTime counter
      staleTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchOnMount: true
    }
  }
});

createInertiaApp({
  resolve: (name) =>
    resolvePageComponent(`./pages/${name}.tsx`, import.meta.glob('./pages/**/*.tsx')),
  title: (title) => {
    return title
      ? `${import.meta.env.VITE_APP_NAME ?? 'Hermix'} - ${title}`
      : import.meta.env.VITE_APP_NAME ?? 'Hermix';
  },
  setup({ el, App, props }) {
    return createRoot(el).render(
      <QueryClientProvider client={queryClient}>
        <TooltipProvider delayDuration={200}>
          <FlashMessages />
          <App {...props} />
        </TooltipProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    );
  }
});
